import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { EndPointRelation } from './endPointRelation';
import { IEnvironmentConfig } from './IEnvironmentConfig';

export const environment: IEnvironmentConfig = {
  production: true,
  backends: [
    {
      url: 'https://checkout-api.production.eshopworld.net/api',
      rel: EndPointRelation.appBackend,
    },
  ],
  backendOrderApiVersion: 'v2',
  backendShipToStoreApiVersion: 'v2',
  backendAppConfigurationVersion: 'v3',

  appInsightsInstrumentalKey: 'c09a9872-a53e-4e81-90d6-91bf2730a34b',
  analyticsGoogleTagManagerContainerId: 'GTM-T7GVF37',
  analyticsTealiumIQAccount: 'go-casual',
  analyticsTealiumIQProfile: 'go-casual',
  analyticsTealiumIQEnvironment: 'prod',
  addressCompleteKey: '',
  addressCompleteName: '',
  googleMapsApiKey: 'AIzaSyDXBvVcF9La7STXRpAqqTHUfx1uOcO2TnI',
  segmentWriteKey: '',
  expressPaymentsPanelUrl: 'https://payments-panel.production.eshopworld.com',
  ravelinPublicKey: 'pk_live_vb1ybS68rBFKPV0fwCnMaXHIYHabZODB',
  imports: [StoreDevtoolsModule.instrument()],
};
